import React from 'react';
import Phaser from 'phaser';
import { gsap } from "gsap";
import _ from 'lodash';

class MyGame extends Phaser.Scene {

    constructor() {
        super({ key: 'main' });
        this.firstJoin = true
        this.gameState = {};
        this.Havecards = [];
        this.Table = [];
        this.TrumpCard = null
        this.websocket = null; 
        this.players = [];
        this.player = [];
        
        this.isDragging = false;
        this.OverItem = null;
        this.DraggingItem = null;
        this.prevState = null;
        this.unacceptCards = [];
        this.usedCards = []
    }

    preload() {
        this.load.setPath("/asset/");
        const svgAssets = [
            "10clubs.svg",
            "10diamonds.svg",
            "10hearts.svg",
            "10spades.svg",
            "2clubs.svg",
            "2diamonds.svg",
            "2hearts.svg",
            "2spades.svg",
            "3clubs.svg",
            "3diamonds.svg",
            "3hearts.svg",
            "3spades.svg",
            "4clubs.svg",
            "4diamonds.svg",
            "4hearts.svg",
            "4spades.svg",
            "5clubs.svg",
            "5diamonds.svg",
            "5hearts.svg",
            "5spades.svg",
            "6clubs.svg",
            "6diamonds.svg",
            "6hearts.svg",
            "6spades.svg",
            "7clubs.svg",
            "7diamonds.svg",
            "7hearts.svg",
            "7spades.svg",
            "8clubs.svg",
            "8diamonds.svg",
            "8hearts.svg",
            "8spades.svg",
            "9clubs.svg",
            "9diamonds.svg",
            "9hearts.svg",
            "9spades.svg",
            "Aclubs.svg",
            "Adiamonds.svg",
            "Ahearts.svg",
            "Aspades.svg",
            "Card.svg",
            "Jclubs.svg",
            "Jdiamonds.svg",
            "Jhearts.svg",
            "Jspades.svg",
            "Kclubs.svg",
            "Kdiamonds.svg",
            "Khearts.svg",
            "Kspades.svg",
            "Qclubs.svg",
            "Qdiamonds.svg",
            "Qhearts.svg",
            "Qspades.svg",
            "back copy.svg",
            "cardBack.svg",
            "user.svg",
            "spades.svg",
            "clubs.svg",
            "hearts.svg",
            "diamonds.svg",
            "transfer.svg"
        ];

        svgAssets.forEach(name => {
            const key = name.replace('.svg', '');
            this.load.svg(key, `${name}`, { scale: window.devicePixelRatio });
        });
        //this.load.svg('cardBackTransfer', 'cardBack.svg',{scale:window.devicePixelRatio});
    }

    importAll(r) {
        let assets = {};
        r.keys().map((item, index) => { assets[item.replace('./', '')] = r(item); });
        return assets;
    }

    getRank(card) {
        const rank = card.RankSuit.match(/^(\d+|\D)/)[0];
        return rank;
    }

    getSuit(card) {
        const rank = card.RankSuit.match(/^(\d+|\D)/)[0];
        const suit = card.RankSuit.slice(rank.length);
        return suit;
    }

    isValidThrow(card) {
        if (this.Table.length <= 0) {
            return true;
        }
    
        return this.Table.some(tableCard => {
            if (!card.RankSuit || !tableCard.down.RankSuit || (tableCard.up && !tableCard.up.RankSuit)) {
                console.warn('One of the cards does not have RankSuit:', { card, tableCard });
                return false;
            }
    
            return this.getRank(card) === this.getRank(tableCard.down) || (tableCard.up && this.getRank(card) === this.getRank(tableCard.up));
        });
    }    

    canBeat(attackingCard, defendingCard) {
        if (defendingCard.texture.key == 'transfer') { return false }
        const suita = this.getSuit(attackingCard);
        const suitd = this.getSuit(defendingCard);
        const ranka = this.getRank(attackingCard);
        const rankd = this.getRank(defendingCard);
        if (suita === this.gameState.trump.suit && suitd !== this.gameState.trump.suit) {
          return true; // Козырь бьет карту другой масти
        }
      
        if (suita === suitd) {
          // Если масть одинакова, сравниваем ранги
          const ranks = ['6', '7', '8', '9', '10', 'J', 'Q', 'K', 'A'];
          return ranks.indexOf(ranka) > ranks.indexOf(rankd);
        }
      
        return false; // Карта не может быть побита
    }

    filterCards() {
        let trumpSuit = this.gameState.trump.suit;
        const suitOrder = ["spades", "clubs", "hearts", "diamonds"];
        const rankOrder = {
            "1": 1, "2": 2, "3": 3, "4": 4, "5": 5, "6": 6, "7": 7, "8": 8, "9": 9, "10": 10,
            "J": 11, "Q": 12, "K": 13, "A": 14
        };

        this.gameState.message.sort((a, b) => {
            const rankA = a.rank;
            const suitA = a.suit;
    
            const rankB = b.rank;
            const suitB = b.suit;
    
            let suitComparison = suitOrder.indexOf(suitA) - suitOrder.indexOf(suitB);
            if (suitComparison !== 0) {
                return suitComparison;
            }
    
            return rankOrder[rankA] - rankOrder[rankB];
        });
    
        if (trumpSuit && suitOrder.includes(trumpSuit)) {
            this.gameState.message = this.gameState.message.filter(card => card.suit !== trumpSuit)
                .concat(this.gameState.message.filter(card => card.suit === trumpSuit));
        }
    }

    moveOtherCards(card) {
        const cardIndex = this.Havecards.indexOf(card);
        const cardWidth = card.width;

        for (let i = 0; i < cardIndex; i++) {
            this.tweens.add({
                targets: this.Havecards[i],
                x: this.Havecards[i].originalX - cardWidth / 4,
                duration: 200,
                ease: 'Power2',
            });
        }

        for (let i = cardIndex + 1; i < this.Havecards.length; i++) {
            this.tweens.add({
                targets: this.Havecards[i],
                x: this.Havecards[i].originalX + cardWidth / 4,
                duration: 200,
                ease: 'Power2',
            });
        }
    }

    resetOtherCards() {
        this.Havecards.forEach(card => {
            if (card.originalX !== undefined) {
                this.tweens.add({
                    targets: card,
                    x: card.originalX,
                    duration: 200,
                    ease: 'Power2',
                });
            }
        });
    }

    addCard(card) {
        if (card) {
            if (card.texture.key === 'transfer') { return }
            this.Havecards.push(card);
            
            card.setInteractive();

            card.on('pointerover', () => {
                if (!this.isDragging && card.isFinished) {
                    this.tweens.add({
                        targets: card,
                        y: card.originalY - 50,
                        duration: 200,
                        ease: 'Power2',
                    });
            
                    this.OverItem = card;
                    if (window.Telegram && window.Telegram.WebApp && window.Telegram.WebApp.HapticFeedback) {
                        window.Telegram.WebApp.HapticFeedback.impactOccurred('light');
                    }
            
                    this.moveOtherCards(card);
                }
            });
            
            card.on('pointerout', () => {
                if (!this.isDragging && card.isFinished) {
                    if (card && card.hasOwnProperty('originalY') && card.originalY !== undefined) {
                        this.tweens.add({
                            targets: card,
                            y: card.originalY,
                            duration: 200,
                            ease: 'Power2',
                        });
                    }

                    this.resetOtherCards()

                    this.OverItem = null;
                }
            });        
        
            let trumpSuit = this.gameState.trump.suit;
            const suitOrder = ["spades", "clubs", "hearts", "diamonds"];
            const rankOrder = {
                "1": 1, "2": 2, "3": 3, "4": 4, "5": 5, "6": 6, "7": 7, "8": 8, "9": 9, "10": 10,
                "J": 11, "Q": 12, "K": 13, "A": 14
            };
        
            // Сортировка карт
            this.Havecards.sort((a, b) => {
                const rankA = this.getRank(a);
                const suitA = this.getSuit(a);
        
                const rankB = this.getRank(b);
                const suitB = this.getSuit(b);
        
                // Сравниваем по масти
                let suitComparison = suitOrder.indexOf(suitA) - suitOrder.indexOf(suitB);
                if (suitComparison !== 0) {
                    return suitComparison;
                }
        
                // Если масти одинаковы, сравниваем по рангу
                return rankOrder[rankA] - rankOrder[rankB];
            });
        
            // Перемещаем козырь в конец списка, если он определен и присутствует в suitOrder
            if (trumpSuit && suitOrder.includes(trumpSuit)) {
                // Фильтруем козырь и добавляем его в конец списка
                this.Havecards = this.Havecards.filter(card => this.getSuit(card) !== trumpSuit)
                    .concat(this.Havecards.filter(card => this.getSuit(card) === trumpSuit));
            }
        }
    }
        
    totalCards() {
        return this.Table.reduce((count, entry) => {
            count += entry.down ? 1 : 0;
            count += entry.up ? 1 : 0;
            return count;
        }, 0)
    };

    trumpCard() {
        this.TrumpCard = this.gameState.trump.rank+this.gameState.trump.suit

        if (this.gameState.lobbyState.cardsCount > 1) {
            this.DeckBackCard = this.add.image(-100*window.devicePixelRatio, this.cameras.main.height/2.2, 'cardBack');
            this.DeckBackCard.setOrigin(0.5, 0.5);
            this.DeckBackCard.setAngle(15);
            this.DeckBackCard.setDepth(0);

            this.tweens.add({
                targets: this.DeckBackCard,
                x: -36*window.devicePixelRatio,
                y: this.cameras.main.height/2.2,
                duration: 500,
                ease: 'Power2'
            });
        }

        if (this.gameState.lobbyState.cardsCount > 0) {
            this.DeckTrumpCard = this.add.image(-100*window.devicePixelRatio, this.cameras.main.height/2.2, this.TrumpCard);
            this.DeckTrumpCard.setOrigin(0.5, 0.5);
            this.DeckTrumpCard.setAngle(105);
            this.DeckTrumpCard.setDepth(-1);
            this.DeckTrumpCard.setScale(0.8);
            this.DeckTrumpCard.RankSuit = this.TrumpCard

            this.tweens.add({
                targets: this.DeckTrumpCard,
                x: -10*window.devicePixelRatio,
                y: this.cameras.main.height/2.2,
                duration: 500,
                ease: 'Power2'
            });
        }

        this.DeckTrump = this.add.image(15*window.devicePixelRatio, this.cameras.main.height/2.1, this.gameState.trump.suit);
        this.DeckTrump.setOrigin(0.5, 0.5);
        this.DeckTrump.setDepth(-10);
        this.DeckTrump.setScale(1);

        this.countDeck = this.add.text(5, this.cameras.main.height-this.cameras.main.height/2.1, '', { font: `${16 * window.devicePixelRatio}px Arial`, fill: '#00ff00' });
        this.countDeck.setText(this.gameState.lobbyState.cardsCount);
    }

    changeCards() {
        const containerWidth = this.cameras.main.width;
        const containerHeight = this.cameras.main.height;
        const cardWidth = 91 * window.devicePixelRatio;
        const cardHeight = 136 * window.devicePixelRatio;
    
        const centerX = containerWidth / 2 + cardWidth / 2;
        const bottomY = containerHeight - cardHeight / 2;

        const totalItems = this.gameState.message.length;
        let newCardIndex = 0;

        this.gameState.message.forEach((cardss, i) => {
            if (this.DraggingItem && this.DraggingItem.RankSuit === cardss.rank + cardss.suit) {
                this.DraggingItem.Index = cardss.index
            }
            this.Havecards.forEach((card) => {
                if (card.RankSuit === cardss.rank + cardss.suit) {
                    card.Index = cardss.index;
                }
            });
        })

        if (this.prevState === 2 && this.gameState.lobbyState.state === 0) {
            if (this.player.role === 1) {
                this.moveCardsToHavecards();
                return;
            }
        }

        this.gameState.message.forEach((cardss, i) => {
            if (this.DraggingItem && this.DraggingItem.RankSuit === cardss.rank + cardss.suit) { return }
            //if (this.Table.find(cardm => cardm.down?.RankSuit === cardss.rank + cardss.suit || cardm.up?.RankSuit === cardss.rank + cardss.suit)) {return;}
            const hasRankSuit = this.Havecards.some(cardm => cardm.RankSuit === cardss.rank + cardss.suit);
            if (hasRankSuit) {
                return;
            }
            const objectExists = this.unacceptCards.some(card => card.RankSuit === cardss.rank + cardss.suit);
            if (objectExists) {return;}

            let card;
            if (cardss.rank+cardss.suit === this.TrumpCard && this.DeckTrumpCard) {
                card = this.DeckTrumpCard;
                card.RankSuit = cardss.rank + cardss.suit;
                card.Index = cardss.index;
                card.setScale(1);
                this.DeckTrumpCard = null;
            }
            else {
                card = this.add.image(-36*window.devicePixelRatio, containerHeight / 2.2, 'cardBack');
                card.setOrigin(0.5, 0.5);
                card.setAngle(15);
                card.setDepth(totalItems - i);
                card.RankSuit = cardss.rank + cardss.suit;
                card.Index = cardss.index;
            }

            this.addCard(card);
    
            const theta = (Math.PI / (totalItems + 1)) * (i + 1);
            const rotation = theta - Math.PI / 2;
            const rotationDegrees = rotation * (35 / Math.PI);
            
            const minOffset = -120;
            const maxOffset = -60;
            const offsetScaleFactor = window.devicePixelRatio
        
            const offsetX = Math.min(Math.max(-(60 + (totalItems * 5)), minOffset), maxOffset) * offsetScaleFactor;
        
            const endX = Math.cos(theta) * offsetX + centerX - cardWidth / 2;
            const endY = Math.sin(theta) * -20 * offsetScaleFactor + bottomY - cardHeight / 2;
    
            card.originalX = endX;
            card.originalY = endY;
            
            let tween = this.tweens.add({
                targets: card,
                x: endX,
                y: endY,
                angle: rotationDegrees,
                duration: 1000,
                ease: 'Power2',
                delay: newCardIndex * 200,
                onComplete: () => {
                    card.isFinished = true;
                    card.setInteractive();
                    if (this.Havecards.length % 2 === 0 && this.Havecards.length / 2 <= i) {
                        const adjustedY = Math.sin(theta) * -20 * offsetScaleFactor + bottomY - cardHeight / 2;
                        this.tweens.add({
                            targets: card,
                            y: adjustedY,
                            duration: 500,
                            ease: 'Power2'
                        });
                    }
                    if (card.texture.key === 'cardBack') {
                        this.flipCard(card);
                    }
                }
            });
            setTimeout(() => {
                card.setDepth(i);
            }, tween.duration / 2);
    
            newCardIndex++; // Увеличиваем индекс новых карт
        });

        this.repositionCards();
    }

    moveCardToTable() {
        if (!this.gameState.table) {
            return;
        }
    
        const shouldClearTable = this.gameState.table.length === 0 && !this.gameState.gameOver;
    
        if (shouldClearTable) {
            if (this.prevState !== 2) {
                this.moveAllCardsToRight();
            }
            this.Table = [];
        }
    
        this.gameState.table.forEach((card, i) => {
            const downRankSuit = card.down.rank + card.down.suit;
            const upRankSuit = card.up ? card.up.rank + card.up.suit : null;

            const existingDownCard = this.Table.find(cardm => cardm.down && cardm.down.RankSuit === downRankSuit);
            const existingUpCard = upRankSuit ? this.Table.find(cardm => cardm.up && cardm.up.RankSuit === upRankSuit) : null;
            const enemy = this.players.find(p => p.id !== this.player.id);

            // sync aaaaaa
            const downCardIndex = this.Havecards.findIndex(card => card.RankSuit === downRankSuit);
            if (downCardIndex !== -1) {
              this.Havecards[downCardIndex].destroy();
              this.Havecards.splice(downCardIndex, 1);
            }
      
            if (upRankSuit) {
              const upCardIndex = this.Havecards.findIndex(card => card.RankSuit === upRankSuit);
              if (upCardIndex !== -1) {
                this.Havecards[upCardIndex].destroy();
                this.Havecards.splice(upCardIndex, 1);
              }
            }

            this.repositionCards();

            if (this.gameState.table && this.gameState.table.length > 0 && 
                this.gameState.table.every(card => !card.up) && 
                this.player?.role === 1 && 
                this.gameState.table.length < 4 &&
                this.gameState.lobbyState.state !== 2) {
                
                const existingTransferCard = this.Table.find(card => card.down && card.down.texture.key === 'transfer');
    
                if (!existingTransferCard) {
                    const transfer = this.add.image(-100 * window.devicePixelRatio, this.cameras.main.height / 2.2, 'transfer');
                    transfer.setOrigin(0.5, 0.5);
                    transfer.setAngle(15);
                    transfer.setDepth(0);
                
                    this.Table.push({ down: transfer, up: null });
    
                    setTimeout(() => {
                        this.updateCardPositions();
                    }, 1);
                }
            }
            else{
                const index = this.Table.findIndex(card => card.down && card.down.texture.key === 'transfer');
                if (index !== -1) {
                    const cardToRemove = this.Table.splice(index, 1)[0];
                    cardToRemove.down.destroy();
                }
                setTimeout(() => {
                    this.updateCardPositions();
                }, 1);
            }

            if (existingDownCard) {
                if (upRankSuit && !existingUpCard) {
                    let index = Math.floor(Math.random() * enemy.backcards.length);
                    const backCardUp = enemy.backcards[index]
                    enemy.backcards.splice(index, 1);
                    const tweens = this.tweens.getTweensOf(backCardUp);
                    tweens.forEach(tween => {
                        tween.stop();
                    });
                    backCardUp.setDepth(1);
                    backCardUp.setAngle(5);
                    backCardUp.RankSuit = upRankSuit;
                    let cardEntry = this.Table.find(entry => entry.down && entry.down.RankSuit === downRankSuit);
                    if (cardEntry) {
                        cardEntry.up = backCardUp;
                    }

                    backCardUp.setTexture(upRankSuit);

                    this.updateCardPositions();
                    this.RepositionCardsBack(enemy)
                }
                return;
            }
    
            let index = Math.floor(Math.random() * enemy.backcards.length);
            const backCardDown = enemy.backcards[index]
            enemy.backcards.splice(index, 1);
            const tweens = this.tweens.getTweensOf(backCardDown);
            tweens.forEach(tween => {
                tween.stop();
            });
            backCardDown.setDepth(0);
            backCardDown.RankSuit = downRankSuit;
            backCardDown.Index = i;
            this.Table.push({ down: backCardDown, up: null });
    
            if (upRankSuit) {
                let index = Math.floor(Math.random() * enemy.backcards.length);
                const backCardUp = enemy.backcards[index]
                enemy.backcards.splice(index, 1);
                const tweens = this.tweens.getTweensOf(backCardUp);
                tweens.forEach(tween => {
                    tween.stop();
                });
                backCardUp.setDepth(1);
                backCardUp.RankSuit = upRankSuit;
                let cardEntry = this.Table.find(entry => entry.down && entry.down.RankSuit === downRankSuit);
                if (cardEntry) {
                    cardEntry.up = backCardUp;
                }
            }
    
            backCardDown.setTexture(downRankSuit);
    
            if (upRankSuit) {
                const upCard = this.Table.find(entry => entry.up && entry.up.RankSuit === upRankSuit);
                if (upCard && upCard.up) {
                    upCard.up.setTexture(upRankSuit);
                }
            }

            this.updateCardPositions();
            this.RepositionCardsBack(enemy)
        });
    }

    endGame() {
        this.unacceptCards.forEach(card => {
            const tweens = this.tweens.getTweensOf(card);
            tweens.forEach(tween => {
                tween.stop();
            });
            this.tweens.add({
                targets: card,
                x: -100*window.devicePixelRatio,
                y: this.cameras.main.height/2.2,
                duration: 500,
                ease: 'Power2',
                onComplete: () => {
                    if (card) {
                        card.destroy()
                    }
                }
            });
        });

        this.Havecards.forEach(card => {
            const tweens = this.tweens.getTweensOf(card);
            tweens.forEach(tween => {
                tween.stop();
            });
            this.tweens.add({
                targets: card,
                x: -100*window.devicePixelRatio,
                y: this.cameras.main.height/2.2,
                duration: 500,
                ease: 'Power2',
                onComplete: () => {
                    if (card) {
                        card.destroy()
                    }
                }
            });
        });

        this.usedCards.forEach(card => {
            const tweens = this.tweens.getTweensOf(card);
            tweens.forEach(tween => {
                tween.stop();
            });
            this.tweens.add({
                targets: card,
                x: -100*window.devicePixelRatio,
                y: this.cameras.main.height/2.2,
                duration: 500,
                ease: 'Power2',
                onComplete: () => {
                    if (card) {
                        card.destroy()
                    }
                }
            });
        });

        this.Table.forEach(card => {
            if (card.down.texture.key === 'transfer') { card.down.destroy() }

            this.tweens.add({
                targets: card.down,
                x: -100*window.devicePixelRatio,
                y: this.cameras.main.height/2.2,
                duration: 500,
                ease: 'Power2',
                onComplete: () => {
                    if (card.down) {
                        card.down.destroy()
                    }
                }
            });

            if (card.up) {
                this.tweens.add({
                    targets: card.up,
                    x: -100*window.devicePixelRatio,
                    y: this.cameras.main.height/2.2,
                    duration: 500,
                    ease: 'Power2',
                    onComplete: () => {
                        if (card.up) {
                            card.up.destroy()
                        }
                    }
                });
            }
        });

        this.players.forEach(pl => {
            if (pl.id !== this.player.id) {
                if (pl.backcards) { // pyk pyk 080824
                    pl.backcards.forEach(card => {
                        const tweens = this.tweens.getTweensOf(card);
                        tweens.forEach(tween => {
                            tween.stop();
                        });
                        this.tweens.add({
                            targets: card,
                            x: -100*window.devicePixelRatio,
                            y: this.cameras.main.height/2.2,
                            duration: 500,
                            ease: 'Power2',
                            onComplete: () => {
                                if (card) {
                                    card.destroy()
                                }
                            }
                        });
                    });
                    pl.backcards = []
                }
            }
        });
        if (this.DraggingItem){
            this.tweens.add({
                targets: this.DraggingItem,
                x: -100*window.devicePixelRatio,
                y: this.cameras.main.height/2.2,
                duration: 500,
                ease: 'Power2',
                onComplete: () => {
                    if (this.DraggingItem) {
                        this.DraggingItem.destroy()
                    }
                }
            });
            this.DraggingItem = null; 
        }

        this.Table = []
        this.unacceptCards = []
        this.Havecards = []
        this.usedCards = []
        this.TrumpCard = null;

        if (this.DeckBackCard) {
            this.DeckBackCard.destroy()
        }
        if (this.DeckTrumpCard) {
            this.DeckTrumpCard.destroy()
        }
        if (this.DeckTrump) {
            this.DeckTrump.destroy()
        }
        if (this.countDeck) {
            this.countDeck.destroy()
        }
    }

    firstJoinF() {  
        if (!this.gameState.trump.suit) { return }
        if (!this.TrumpCard) {
            this.trumpCard();
        }
        if (this.countDeck) {
            const cardCountText = this.gameState.lobbyState.cardsCount === 0 ? '' : this.gameState.lobbyState.cardsCount.toString();
            this.countDeck.setText(cardCountText);
        }
        
        this.players.forEach(pl => {
            if (pl.id !== this.player.id) {
                this.DealtCardOpponent(pl);
            }
        });
    
        if (this.gameState.message) {
            this.filterCards();
            this.changeCards();
        }
    
        this.gameState.table.forEach((card, i) => {
            const downRankSuit = card.down.rank + card.down.suit;
            const upRankSuit = card.up ? card.up.rank + card.up.suit : null;

            const existingDownCard = this.Table.find(cardm => cardm.down && cardm.down.RankSuit === downRankSuit);
            const existingUpCard = upRankSuit ? this.Table.find(cardm => cardm.up && cardm.up.RankSuit === upRankSuit) : null;
            const enemy = this.players.find(p => p.id !== this.player.id);

            if (existingDownCard) {
                if (upRankSuit && !existingUpCard) {
                    const backCardUp = this.add.image(-100 * window.devicePixelRatio, this.cameras.main.height / 2.2, 'cardBack');
                    backCardUp.setOrigin(0.5, 0.5);
                    backCardUp.setDepth(1);
                    backCardUp.setAngle(5);
                    backCardUp.RankSuit = upRankSuit;
                    let cardEntry = this.Table.find(entry => entry.down && entry.down.RankSuit === downRankSuit);
                    if (cardEntry) {
                        cardEntry.up = backCardUp;
                    }

                    backCardUp.setTexture(upRankSuit);
    
                    this.updateCardPositions();
                    this.RepositionCardsBack(enemy)
                }
                return;
            }
    
            const backCardDown = this.add.image(-100 * window.devicePixelRatio, this.cameras.main.height / 2.2, 'cardBack');
            backCardDown.setOrigin(0.5, 0.5);
            backCardDown.setDepth(0);
            backCardDown.RankSuit = downRankSuit;
            backCardDown.Index = i;
            this.Table.push({ down: backCardDown, up: null });
    
            if (upRankSuit) {
                const backCardUp = this.add.image(-100 * window.devicePixelRatio, this.cameras.main.height / 2.2, 'cardBack');
                backCardUp.setOrigin(0.5, 0.5);
                backCardUp.setDepth(1);
                backCardUp.RankSuit = upRankSuit;
                let cardEntry = this.Table.find(entry => entry.down && entry.down.RankSuit === downRankSuit);
                if (cardEntry) {
                    cardEntry.up = backCardUp;
                }
            }
    
            backCardDown.setTexture(downRankSuit);
    
            if (upRankSuit) {
                const upCard = this.Table.find(entry => entry.up && entry.up.RankSuit === upRankSuit);
                if (upCard && upCard.up) {
                    upCard.up.setTexture(upRankSuit);
                }
            }

            this.updateCardPositions();
            this.RepositionCardsBack(enemy)
        });

        if (this.gameState.table && this.gameState.table.length > 0 && 
            this.gameState.table.every(card => !card.up) && 
            this.player?.role === 1 && 
            this.gameState.table.length < 4 &&
            this.gameState.lobbyState.state !== 2) {
            
            const existingTransferCard = this.Table.find(card => card.down && card.down.texture.key === 'transfer');

            if (!existingTransferCard) {
                const transfer = this.add.image(-100 * window.devicePixelRatio, this.cameras.main.height / 2.2, 'transfer');
                transfer.setOrigin(0.5, 0.5);
                transfer.setAngle(15);
                transfer.setDepth(0);
            
                this.Table.push({ down: transfer, up: null });

                setTimeout(() => {
                    this.updateCardPositions();
                }, 1);
            }
        }
        else{
            const index = this.Table.findIndex(card => card.down && card.down.texture.key === 'transfer');
            if (index !== -1) {
                const cardToRemove = this.Table.splice(index, 1)[0];
                cardToRemove.down.destroy();
            }
            setTimeout(() => {
                this.updateCardPositions();
            }, 1);
        }

        /*console.log(this.tweens.tweens)
        const allTweens = this.tweens.tweens;
        console.log(allTweens)
        allTweens.forEach(tween => { // жопааааа
            tween.targets.forEach(target => {
                tween.data.forEach(tweenData => {
                    if (tweenData.key === 'x') {
                        target.x = tweenData.end;
                    } else if (tweenData.key === 'y') {
                        target.y = tweenData.end;
                    } else if (tweenData.key === 'scaleX') {
                        target.scaleX = tweenData.end;
                    } else if (tweenData.key === 'scaleY') {
                        target.scaleY = tweenData.end;
                    } else if (tweenData.key === 'angle') {
                        target.angle = tweenData.end;
                    }
                });
            });
            //tween.complete();//
        });*/
    }

    setGameState(newState) {
        let nw = _.omit(newState, ['message']);
        let gmw = _.omit(this.gameState, ['message']);
        if (_.isEqual(gmw, nw)) {
            return;
        }

        this.prevState = this.gameState.lobbyState?.state;
        this.gameState = newState;
        const newPlayers = newState.players;
        this.player = newState.player;
        newPlayers.forEach(newPlayer => {
            if (newPlayer.id !== this.player.id) {
                const existingPlayer = this.players.find(player => player.id === newPlayer.id);
                if (existingPlayer && existingPlayer.backcards) {
                    newPlayer.backcards = existingPlayer.backcards;
                    //newPlayer.avatar = existingPlayer.avatar;
                }
                else{
                    newPlayer.backcards = []
                    //newPlayer.avatar = null;
                }
            }
        });
        this.players = newPlayers;
        if (this.firstJoin) {
            this.firstJoinF();
            this.firstJoin = false;
            return;
        }
        if (!newState.trump.suit) { return }
        if (!this.TrumpCard) {
            this.trumpCard();
        }
        if (newState.lobbyState.cardsCount <= 1 && this.DeckBackCard) {
            this.DeckBackCard.destroy();
            this.DeckBackCard = null;
        }
        if (this.countDeck){
            const cardCountText = this.gameState.lobbyState.cardsCount === 0 ? '' : this.gameState.lobbyState.cardsCount.toString();
            this.countDeck.setText(cardCountText);
        }
        this.players.forEach(pl => {
            if (pl.id !== this.player.id) {
                this.DealtCardOpponent(pl);
            }
        });
        if (newState.message) {
            this.filterCards();
            this.changeCards();
        }
        this.moveCardToTable();
    }

    sendWebSocketMessage(message) {
        if (this.websocket && this.websocket.readyState === WebSocket.OPEN) {
            this.websocket.send(JSON.stringify(message));
        }
    }

    create() {
        //this.fpsText = this.add.text(10, 10, '', { font: '16px Arial', fill: '#00ff00' });

        const realw = window.innerWidth;
        const realh = window.innerHeight;
        const ratio = window.devicePixelRatio
        const widthDPR = Math.round(realw * ratio );
        const heightDPR = Math.round(realh * ratio );
        
        this.scale.canvas.width = widthDPR;
        this.scale.canvas.height = heightDPR;
        this.scale.canvas.style.width = Math.round(realw) + 'px';
        this.scale.canvas.style.height = Math.round(realh) + 'px';

        this.offsetCardUpX = 10;
        this.offsetCardUpY = 10;

        const isMobileDevice = this.sys.game.device.os.android || this.sys.game.device.os.iOS;

        this.input.on('pointermove', (pointer) => {
            if (isMobileDevice && Math.abs(pointer.velocity.y) > 100 && this.OverItem) {
                this.DraggingItem = this.OverItem;
                let card = this.DraggingItem;

                const tweens = this.tweens.getTweensOf(card);
                tweens.forEach(tween => {
                    tween.stop();
                });

                card.startDragX = card.x;
                card.startDragY = card.y;
                card.setDepth(100);
                this.Havecards = this.Havecards.filter(item => item !== card);
                this.repositionCards();
                    
                this.isDragging = true;
                document.getElementById('addPlayButton').style.pointerEvents = 'none';
                this.OverItem = null;
            }

            if (this.isDragging && this.DraggingItem) {
                let card = this.DraggingItem
                card.x = pointer.position.x
                card.y = pointer.position.y
            }
        })

        if (!isMobileDevice) {
            this.input.on('pointerdown', (pointer, targets) => {
                if (this.OverItem) {
                    this.DraggingItem = this.OverItem;
                    let card = this.DraggingItem;

                    const tweens = this.tweens.getTweensOf(card);
                    tweens.forEach(tween => {
                        tween.stop();
                    });

                    card.startDragX = card.x;
                    card.startDragY = card.y;
                    card.setDepth(100);
                    this.Havecards = this.Havecards.filter(item => item !== card);
                    this.repositionCards();
                        
                    this.isDragging = true;
                    document.getElementById('addPlayButton').style.pointerEvents = 'none';
                    this.OverItem = null;
                }
            });
        }

        this.input.on('pointerup', (pointer) => {
            if (this.isDragging && this.DraggingItem) {
                let object = this.DraggingItem
                this.DraggingItem = null;
                this.isDragging = false;
                document.getElementById('addPlayButton').style.pointerEvents = 'auto';
                let distanceY = Math.abs(object.startDragY - object.y);

                if (distanceY < 100) {
                    this.addCard(object);
                    setTimeout(()=> { // wtf
                        this.repositionCards();
                    },1)
                    return;
                }

                let isMouseOverCard = false;
                let cardUp = null
                let cardDown = null;
    
                this.Table.forEach(containerCard => {
                    const containerCardBounds = containerCard.down.getBounds();
                    if (Phaser.Geom.Rectangle.Contains(containerCardBounds, pointer.worldX, pointer.worldY)) {
                        let stack = this.Table.find(cardm => cardm.down && cardm.down.RankSuit === containerCard.down.RankSuit);
                        if (stack) {
                            cardUp = stack.up;
                            if (!stack.up) {
                                isMouseOverCard = true;
                                containerCard.down.Index = this.gameState.table.findIndex(cardm => 
                                    cardm.down && (cardm.down.rank + cardm.down.suit === containerCard.down.RankSuit)
                                );
                                cardDown = containerCard.down;
                            }
                        }
                    }
                });
                if (this.player.role == 1) {
                    console.log(cardUp)
                    if (cardDown && !cardUp) {
                        object.off("pointerover");
                        object.off('pointerout');

                        if (cardDown.texture.key === 'transfer') {
                            if (this.isValidThrow(object) && this.players.find(p => p.id !== this.player.id).cardsLen >= this.Table.filter(card => card.up === null).length) {
                                this.sendWebSocketMessage({
                                    type: "makeMove",
                                    typeMove: "transfer",
                                    down: object.Index,
                                });
                                const index = this.Table.findIndex(card => card.down === cardDown);
                                if (index !== -1) {
                                    const cardToRemove = this.Table.splice(index, 1)[0];
                                    cardToRemove.down.destroy();
                                }
                                this.Table.push({ down: object, up: null });
                                this.unacceptCards.push(object);
                                setTimeout(() => {
                                    const cardExistsInTable = this.gameState.table.some(card => 
                                        card.down && (card.down.rank + card.down.suit === object.RankSuit)
                                    );

                                    if (!cardExistsInTable) {
                                        const index = this.Table.findIndex(entry => 
                                            entry.down && entry.down.RankSuit === object.RankSuit
                                        );
                                        if (index !== -1) {
                                            this.Table.splice(index, 1);
                                            this.unacceptCards = this.unacceptCards.filter(card => card !== object);
                                            this.addCard(object);
                                            setTimeout(()=> { // wtf
                                                this.repositionCards();
                                            },1)
                                            const transfer = this.add.image(-100 * window.devicePixelRatio, this.cameras.main.height / 2.2, 'transfer');
                                            transfer.setOrigin(0.5, 0.5);
                                            transfer.setAngle(15);
                                            transfer.setDepth(0);
                                        
                                            this.Table.push({ down: transfer, up: null });
                                        }
                                    }
                                    this.updateCardPositions();
                                }, 3000);
                            }
                            else
                            {
                                this.addCard(object);
                                setTimeout(()=> { // wtf
                                    this.repositionCards();
                                },1)
                            }

                        }
                        else
                        {
                            if (this.canBeat(object,cardDown) && this.gameState.lobbyState.state !== 2){
                                this.sendWebSocketMessage({
                                    type: "makeMove",
                                    typeMove: "attack",
                                    up: object.Index,
                                    down: cardDown.Index,
                                });
                                let cardEntry = this.Table.find(entry => entry.down && entry.down.RankSuit === cardDown.RankSuit);
                                if (cardEntry) {
                                    cardEntry.up = object;
                                }
                                this.unacceptCards.push(object);
                                setTimeout(() => {
                                    console.log(this.gameState.table)
                                    const cardExistsInTable = this.gameState.table.some(card => 
                                        card.up && (card.up.rank + card.up.suit === object.RankSuit)
                                    );
                                
                                    if (!cardExistsInTable) {
                                        const index = this.Table.findIndex(entry => 
                                            entry.up && entry.up.RankSuit === object.RankSuit
                                        );

                                        if (index !== -1) {
                                            const cardToRemove = this.Table[index];
                                            if (cardToRemove.up) {
                                                cardToRemove.up = null;
                                            }
                                            this.unacceptCards = this.unacceptCards.filter(card => card !== object);

                                            this.addCard(object);
                                            setTimeout(()=> { // wtf
                                                this.repositionCards();
                                            },1)
                                        }
                                    }
                                    this.updateCardPositions();
                                }, 3000);
                            }
                            else
                            {
                                this.addCard(object);
                                setTimeout(()=> { // wtf
                                    this.repositionCards();
                                },1)
                            }
                        }
                    }
                    else{
                        this.addCard(object);
                        setTimeout(()=> { // wtf
                            this.repositionCards();
                        },1)
                    }
                } else {
                    object.off("pointerover");
                    object.off('pointerout');
                    //this.gameState.lobbyState.usedCards > 0
                    if (this.isValidThrow(object) && this.players.find(p => p.id !== this.player.id).cardsLen >= this.Table.filter(card => card.up === null).length + 1) {
                        this.sendWebSocketMessage({
                            type: "makeMove",
                            typeMove: "throw",
                            down: object.Index,
                        });
                        this.Table.push({ down: object, up: null });
                        this.unacceptCards.push(object);
                        setTimeout(() => {
                            console.log(this.gameState.table)
                            const cardExistsInTable = this.gameState.table.some(card => 
                                card.down && (card.down.rank + card.down.suit === object.RankSuit)
                            );
                        
                            if (!cardExistsInTable) {
                                const index = this.Table.findIndex(entry => 
                                    entry.down && entry.down.RankSuit === object.RankSuit
                                );
                                if (index !== -1) {
                                    this.Table.splice(index, 1);
                                    this.unacceptCards = this.unacceptCards.filter(card => card !== object);
                                    this.addCard(object);
                                    setTimeout(()=> { // wtf
                                        this.repositionCards();
                                    },1)
                                }
                            }
                            this.updateCardPositions();
                        }, 3000);
                    }
                    else
                    {
                        this.addCard(object);
                        setTimeout(()=> { // wtf
                            this.repositionCards();
                        },1)
                    }
                }
                object.setDepth(0);
                object.setAngle(-5);
                this.updateCardPositions();
            }
        })

        /*const assets = this.importAll(require.context('../assets', false, /\.(png|jpe?g|gif|svg)$/));

        for (const asset in assets) {
            const key = asset.split('.').slice(0, -1).join('.');
            this.load.svg(key,assets[asset],{scale:window.devicePixelRatio});
        }
        
        this.load.start();*/

        //this.load.on('complete', () => {
            this.game.events.emit('scene-ready', this);
        //});
    }

    RepositionCardsBack(ply) {  
        const centerY = 80 * window.devicePixelRatio;
        const centerX = this.cameras.main.width / 2;
        const radius = 60 * window.devicePixelRatio;
        
        let len = ply.backcards.length
        const middleIndex = Math.floor(len / 2);
    
        for (let i = 0; i < len; i++) {
            const theta = (Math.PI / (len + 1)) * (i + 1);
            const rotationa = theta - Math.PI / 2;
            const rotationDegrees = rotationa * (-40 / Math.PI);
            const rotationRadians = rotationDegrees * (Math.PI / 180);
    
            const x = centerX + radius * -Math.sin(rotationRadians);
            let y = centerY + radius * Math.cos(rotationRadians);
    
            if (i < middleIndex) {
                y -= (10 * window.devicePixelRatio) * ((middleIndex - i) / middleIndex); // Подъем карты до середины
            } else if (i > middleIndex) {
                y -= (10  * window.devicePixelRatio) * ((i - middleIndex) / middleIndex); // Опускание карты после середины
            }
    
            const card = ply.backcards[i];
            
            card.anim = this.tweens.add({
                targets: card,
                scale:0.5,
                x: x, // Конечная X позиция с учетом угла
                y: y, // Конечная Y позиция с учетом подъема и опускания
                angle: rotationDegrees,
                duration: 1000,
                delay: i * 200,
                ease: 'Power2',
            });
        }
    }

    DealtCardOpponent(ply){
        if (this.prevState === 2 && this.gameState.lobbyState.state === 0) {
            if (this.player.role === 0) {
                this.Table.forEach((card) => {
                    this.flipCard(card.down);
                    ply.backcards.push(card.down);
        
                    if (card.up) {
                        this.flipCard(card.up);
                        ply.backcards.push(card.up);
                    }
                });
            }
        }

        let len = ply.cardsLen - ply.backcards.length
        /*const middleIndex = Math.floor(len / 2);

        let skipIndices = [];

        for (let i = 0; i < len; i++) {
            skipIndices.push(ply.backcards.length+i);
        }*/

        for (let i = 0; i < len; i++) {
            /*const angle = angleStart + i * (angleEnd - angleStart) / (len - 1);
            const angleRad = (angle * Math.PI) / 180;
            const x = centerX + radius * Math.cos(angleRad);
            let y = centerY + radius * Math.sin(angleRad);
            const rotation = angle - 90;

            if (i < middleIndex) {
                y -= 20 * ((middleIndex - i) / middleIndex); // Подъем карты до середины
            } else if (i > middleIndex) {
                y -= 20 * ((i - middleIndex) / middleIndex); // Опускание карты после середины
            }*/

            let card;
            if (this.DeckTrumpCard && this.gameState.lobbyState.cardsCount <= 0) {
                card = this.DeckTrumpCard;
                card.setScale(1);
                this.flipCard(card);
                this.DeckTrumpCard = null;
            }
            else {
                card = this.add.image(-36*window.devicePixelRatio, this.cameras.main.height / 2.2, 'cardBack');
                card.setOrigin(0.5, 0.5);
                card.setAngle(15);
                card.setDepth(-1);
            }

            ply.backcards.push(card);
            
/*            this.tweens.add({
                targets: card,
                scale: 0.5,
                x: x, // Конечная X позиция с учетом угла
                y: y,
                angle: rotation,
                duration: 1000,
                delay: i * 200, // Задержка увеличивается с каждой картой
                ease: 'Power2',
            });*/
        }
        setTimeout(()=> { // wtf
            this.RepositionCardsBack(ply);
        },1)
    }    

    repositionCards() {
        const containerWidth = this.cameras.main.width;
        const containerHeight = this.cameras.main.height;
        const cardWidth = 91 * window.devicePixelRatio;
        const cardHeight = 136 * window.devicePixelRatio;
        
        const centerX = containerWidth / 2 + cardWidth / 2;
        const bottomY = containerHeight - cardHeight / 2;
        
        const totalItems = this.Havecards.length;

        this.Havecards.forEach((card, i) => {
            if (!card.isFinished) {
                return;
            }
        
            const theta = (Math.PI / (totalItems + 1)) * (i + 1);
            const rotation = theta - Math.PI / 2;
            const rotationDegrees = rotation * (35 / Math.PI);

            const minOffset = -120;
            const maxOffset = -60;
            const offsetScaleFactor = window.devicePixelRatio
        
            const offsetX = Math.min(Math.max(-(60 + (totalItems * 5)), minOffset), maxOffset) * offsetScaleFactor;
        
            const endX = Math.cos(theta) * offsetX + centerX - cardWidth / 2;
            const endY = Math.sin(theta) * -20 * offsetScaleFactor + bottomY - cardHeight / 2;

            card.setDepth(i)
            card.originalX = endX;
            card.originalY = endY;
    
            this.tweens.add({
                targets: card,
                x: endX,
                y: endY,
                angle: rotationDegrees, // Конечная ротация
                scale: 1,
                duration: 300,
                ease: 'Power2',
            });
        });
    }

    moveCardsToHavecards() {
        this.Table.forEach((card) => {
            card.down.isFinished = true;
            this.addCard(card.down);

            if (card.up) {
                card.up.isFinished = true;
                this.addCard(card.up);
            }
        });
        const index = this.Table.findIndex(card => card.down && card.down.texture.key === 'transfer');
        if (index !== -1) {
            const cardToRemove = this.Table.splice(index, 1)[0];
            cardToRemove.down.destroy();
        }
        setTimeout(()=> { // wtf
            this.repositionCards(); 
        },1)
    }

    moveAllCardsToRight() {
        const targetX = this.cameras.main.width;
    
        const moveCard = (card, targetX) => {
            this.flipCard(card, () => {
                setTimeout(() => {
                    const yy = Phaser.Math.Between(this.cameras.main.height/2-this.cameras.main.height/4, this.cameras.main.height/2+this.cameras.main.height/4)
                    this.tweens.add({
                        targets: card,
                        x: targetX+Phaser.Math.Between(0,50*window.devicePixelRatio),
                        y: yy,
                        angle: Phaser.Math.Between(0,160),
                        duration: 500,
                        ease: 'Power2',
                    });
                }, 200);
                this.usedCards.push(card);
            });
        };
    
        this.Table.forEach(entry => {
            moveCard(entry.down, targetX);
            if (entry.up) {
                moveCard(entry.up, targetX);
            }
        });        
    }      
    
    flipCard(card, onComplete) {
        const currentScaleX = card.scaleX;
        const currentScaleY = card.scaleY;
        const newTexture = card.texture.key === card.RankSuit ? 'cardBack' : card.RankSuit;
    
        this.tweens.add({
            targets: card,
            scaleX: 0,
            duration: 100,
            ease: 'Linear',
            onComplete: () => {
                card.setTexture(newTexture);
                this.tweens.add({
                    targets: card,
                    scaleX: currentScaleX,
                    scaleY: currentScaleY,
                    duration: 100,
                    ease: 'Linear',
                    onComplete: () => {
                        if (onComplete) {
                            onComplete();
                        }
                    }
                });
            }
        });
    }        
    
    calculateCardPosition(index) {
        const numCards = this.Table.length;
        const cardSpacingX = 20*window.devicePixelRatio;
        const scale = 0.80 * (this.cameras.main.width / 375.0);
        const cardWidth = 91 * scale;
        const cardHeight = 136 * scale;
        const cardSpacingY = 20*window.devicePixelRatio;
        const numCardsPerRow = 3;
    
        const centerX = this.cameras.main.width / 2;
        const centerY = this.cameras.main.height / 2;
    
        const numRows = Math.ceil(numCards / numCardsPerRow);
        const totalHeight = numRows * cardHeight + (numRows - 1) * cardSpacingY;
        const startY = centerY - totalHeight / 2;
    
        const row = Math.floor(index / numCardsPerRow);
        const col = index % numCardsPerRow;
    
        const cardsInCurrentRow = Math.min(numCardsPerRow, numCards - row * numCardsPerRow);
        const totalWidth = cardsInCurrentRow * cardWidth + (cardsInCurrentRow - 1) * cardSpacingX;
        const startX = centerX - totalWidth / 2;
    
        const x = startX + col * (cardWidth + cardSpacingX) + cardWidth / 2;
        const y = startY + row * (cardHeight + cardSpacingY) + cardHeight / 2;
    
        return {
            x: x,
            y: y
        };
    }       
    
    updateCardPositions(animate = true) {
        var cards = this.Table;
    
        const transferIndex = cards.findIndex(card => card.down.texture.key === 'transfer');
        if (transferIndex !== -1) {
            const transferCard = cards.splice(transferIndex, 1)[0];
            cards.push(transferCard);
        }

        this.Table.forEach((card, i) => {
            var {x, y} = this.calculateCardPosition(i);

            if (card.down) {
                this.tweens.add({
                    targets: card.down,
                    x: x,
                    y: y,
                    scale:0.80,
                    angle:-5,
                    duration: 500,
                    ease: 'Power2',
                    onComplete: () => { 
                        card.down.setOrigin(0.5, 0.5);
                    },
                });

                if (card.up) {
                    this.tweens.add({
                        targets: card.up,
                        x: x+10,
                        y: y+10,
                        scale:0.80,
                        angle:5,
                        duration: 500,
                        ease: 'Power2',
                    });
                }
            }
        })
    }  

    updateContainerGrid(skipCard = null, xz = 0) {
        const numCards = this.Table.length;
        const cardSpacingX = 20;
        const cardWidth = 60; // Ширина карты
        const cardHeight = 90; // Высота карты
        const cardSpacingY = 20;
        const numCardsPerRow = 3;
    
        // Определяем центр контейнера
        const centerX = this.movedCardsContainer.width / 2;
        const centerY = this.movedCardsContainer.height / 2;
    
        // Рассчитываем, сколько рядов карт должно быть в контейнере
        const numRows = Math.ceil(numCards / numCardsPerRow);
    
        // Рассчитываем общее смещение по оси Y, чтобы выровнять все ряды по центру контейнера
        const totalHeight = numRows * cardHeight + (numRows - 1) * cardSpacingY;
        const startY = centerY - totalHeight / 2;
    
        // Проходим по всем картам и вычисляем их позиции в контейнере
        this.Table.forEach((card, index) => {
            const row = Math.floor(index / numCardsPerRow);
            const col = index % numCardsPerRow;
    
            // Рассчитываем общее смещение по оси X, чтобы выровнять карты в текущем ряду по центру контейнера
            const cardsInCurrentRow = Math.min(numCardsPerRow, numCards - row * numCardsPerRow);
            const totalWidth = cardsInCurrentRow * cardWidth + (cardsInCurrentRow - 1) * cardSpacingX;
            const startX = centerX - totalWidth / 2;
    
            // Рассчитываем позицию карты в контейнере с учетом смещения по осям X и Y
            const x = startX + col * (cardWidth + cardSpacingX);
            const y = startY + row * (cardHeight + cardSpacingY);
    
            // Устанавливаем позицию карты с анимацией, если карта не является новой
            if (card.down !== skipCard) {
                gsap.to(card.down, { x: x, y: y, duration: 0.5, ease: "power2.out" });
                //let stack = this.Table.find(cardm => cardm.down && cardm.down.RankSuit === card.RankSuit);
                if (card.up) {
                    gsap.to(card.up, { x: x+this.offsetCardUpX, y: y+this.offsetCardUpY, duration: 0.5, ease: "power2.out" });
                }
            } else {
                card.down.setPosition(x, y);
            }
        });
    }    
}

export default MyGame;
