import React, { useEffect, useState, useRef, useContext, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { WebSocketContext } from './App';

function RewardGame() {
  const location = useLocation();
  const reward = useNavigate();
  const [rewards, setRewards] = useState(null);
  const [isSubscribe, setSubrscribe] = useState(false);
  const websocket = useContext(WebSocketContext);

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleString();
  };

  const handleRewardsyMessage = useCallback((event) => {
    try {
      const parsedMessage = JSON.parse(event.data);

      switch (parsedMessage.type) {
        case "getRewards":
          console.log(parsedMessage.message);
          setRewards(parsedMessage.message);
          break;
        default:
          console.error('Неизвестный тип сообщения:', parsedMessage.type);
      }
    } catch (error) {
      console.error('Ошибка при обработке сообщения:', error);
    }
  }, [reward]);

  useEffect(() => {
    if (websocket) {
      websocket.websocket.addEventListener('message', handleRewardsyMessage);
      if (!isSubscribe) {
        websocket.websocket.send(JSON.stringify({ type: "subscribeRewards" }));
        setSubrscribe(true)
      }
    }

    return () => {
      if (websocket) {
        websocket.websocket.removeEventListener('message', handleRewardsyMessage);
      }
    };
  }, [websocket, handleRewardsyMessage]);

  return (
    <div className='rewards'>
      <div className="container-reward">
        {rewards === null ? (
         <div className='loading-icon'></div>
        ) : (
            rewards.map((his, index) => (
            <div className="item" key={index}>
              <span>Winner: {his.winner.nickname}</span>
              <span>Loser: {his.loser.nickname}</span>
              <span>{formatDate(his.timestamp)}</span>
            </div>
          ))
        )}
      </div>
    </div>
  );
}

export default RewardGame;
