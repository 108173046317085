import React, { useEffect, useState, useContext, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { WebSocketContext } from './App';

function Lead() {
  const history = useNavigate();
  const websocket = useContext(WebSocketContext);
  const [leaders, setLeaders] = useState(null);
  const location = useLocation();

  const handleProfileMessage = useCallback((event) => {
    try {
      const parsedMessage = JSON.parse(event.data);

      console.log(parsedMessage);

      switch (parsedMessage.type) {
        case "getLeaders":
          console.log(parsedMessage.message);
          setLeaders(parsedMessage.message);
          break;
        default:
          console.error('Неизвестный тип сообщения:', parsedMessage.type);
      }
    } catch (error) {
      console.error('Ошибка при обработке сообщения:', error);
    }
  }, []);

  useEffect(() => {
    window.Telegram.WebApp.BackButton.show();
    if (window.Telegram.WebApp.backButtonHandler) {
        window.Telegram.WebApp.offEvent('backButtonClicked', window.Telegram.WebApp.backButtonHandler);
    }

    window.Telegram.WebApp.backButtonHandler = () => {
        history('/profile')
        window.Telegram.WebApp.BackButton.hide();
    };

    window.Telegram.WebApp.onEvent('backButtonClicked', window.Telegram.WebApp.backButtonHandler);

    /*if (websocket) {
      websocket.websocket.addEventListener('message', handleProfileMessage);
      websocket.websocket.send(JSON.stringify({ type: "subscribeProfile" }));
    }

    return () => {
      if (websocket) {
        websocket.websocket.removeEventListener('message', handleProfileMessage);
      }
    };*/
    //setProfile(JSON.parse(sessionStorage.getItem('playerProfile')));
  }, [websocket]);

  return (
    <div className='lead'>
    </div>
  );
}

export default Lead;
