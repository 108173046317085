import React, { useEffect, useState,useRef } from 'react';
import Phaser, { ScaleModes } from 'phaser';
import { useParams,useNavigate } from 'react-router-dom';
import MyGameScene from './Game';

const GameComponent = () => {
  const history = useNavigate();
  const { id } = useParams();
  const [state, setState] = useState({});
  const wsRef = useRef(null);
  const PingIntervalRef = useRef(null);
  const PongTimeoutRef = useRef(null);
  const WebTimeoutRef = useRef(null);
  const [isSceneLoaded, setIsSceneLoaded] = useState(false);
  const [isConnected, setIsConnected] = useState(false);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [playerBlocks, setPlayerBlocks] = useState([]);
  const [endGame, setEndGame] = useState([]);
  const cleanupRef = useRef(null);
  let isTimeoutSet = false;

  let hasNotifiedLowTime = false;

  function updateProgress(lobbyState) {
    // Проверяем, существует ли lobbyState, имеет ли он свойство players и serverTime
    if (!lobbyState || !Array.isArray(lobbyState.players) || !lobbyState.serverTime) {
      return; // Прерываем выполнение функции, если проверка не пройдена
    }
  
    // Теперь выполняем логику функции, зная, что lobbyState существует
    lobbyState.players.forEach(player => {
      if (player && player.timer) {
        let progressRatio = 1; // по умолчанию круг полностью заполнен
  
        if (!((lobbyState.state === -1 && player.ready === true) || player.timer.startTime === player.timer.endTime)) {
          const currentTime = Date.now();
          const timeDifference = currentTime - lobbyState.serverTime;
          const adjustedCurrentTime = player.timer.startTime + timeDifference;
          const remainingTime = (player.timer.endTime - adjustedCurrentTime) / 1000;
          const totalTime = (player.timer.endTime - player.timer.startTime) / 1000;
          progressRatio = Math.max(0, Math.min(remainingTime / totalTime, 1)); // Ограничиваем значения от 0 до 1
          
          if (player.id === window.Telegram.WebApp.initDataUnsafe.user.id) {
            if (remainingTime <= 10 && remainingTime > 0 && !hasNotifiedLowTime) {
              if (window.Telegram && window.Telegram.WebApp && window.Telegram.WebApp.HapticFeedback) {
                window.Telegram.WebApp.HapticFeedback.notificationOccurred("error");
              }
              hasNotifiedLowTime = true;
            } else if (remainingTime > 10) {
              hasNotifiedLowTime = false;
            }
          }
        }
  
        const element = document.querySelector(`.inner-footer[data-player-id="${player.id}"] .progress-bar`);
        if (element) {
          const dashOffset = 82 * (1 - progressRatio); // 82 - максимальное значение stroke-dashoffset
          element.style.strokeDashoffset = dashOffset;
        }
      }
    });
  }

  const connectWebSocket = (scene) => {
    if (!wsRef.current || wsRef.current.readyState !== WebSocket.OPEN) {
      const websocket = new WebSocket(`wss://ws.durakgram.com/game/${id}/?initData=${encodeURIComponent(window.Telegram.WebApp.initData)}`);
      wsRef.current = websocket;

      window.websocket = websocket

      websocket.onmessage = (event) => {
        try {
          const parsedMessage = JSON.parse(event.data);
          let payload = parsedMessage.message;
          //console.log(payload);
          console.log(parsedMessage.type)
          switch (parsedMessage.type) {
            case 'lobbyState':
              setState((prev) => {
                let state = {
                  lobbyState: payload,
                  players: payload.players,
                  trump: payload.trumpCard,
                  player: payload.players.find(player => player.id === window.Telegram.WebApp.initDataUnsafe.user.id),
                  message: (payload.players.find(player => player.id === window.Telegram.WebApp.initDataUnsafe.user.id))?.hand.map((card, index) => ({
                    ...card,
                    index: index
                  })) || null,
                  table: payload.table,
                };
                
                const blocks = Array(state.lobbyState.maxplayers - 1).fill(null);

                state.players.forEach(player => {
                  if (player.id !== state.player.id) {
                    const index = blocks.findIndex(block => block === null);
                    if (index !== -1) {
                      blocks[index] = player;
                    }
                  }
                });

                setPlayerBlocks(blocks);

                if (state) {
                  if (state.player?.currentAction === '') {
                    setButtonClicked(false);
                  } else {
                    setButtonClicked(true);
                  }
                  
                  if (state.lobbyState?.state !== -1) {
                    if (state.table?.length <= 0) {
                      setButtonClicked(true);
                      console.log('pysto')
                    } else if (state.player?.currentAction === 'pass') {
                      setButtonClicked(true);
                      console.log('pass')
                    } else if ((state.lobbyState?.state === 2 && state.player?.role === 0) || 
                      (state.lobbyState?.state === 3 && state.player?.role === 1)) {
                      setButtonClicked(false);
                      console.log('pass вроде как')
                    } else if (state.player?.role === 1 && state.table?.every(card => card.up !== null)) {
                      setButtonClicked(true);
                      console.log('тут тож уй')
                    } else if (state.player?.role === 0 && state.table?.find(card => card.up === null)) {
                      setButtonClicked(true);
                      console.log('pк')
                    }
                  } else {
                    if (state.players.length < state.lobbyState.maxplayers) {
                      setButtonClicked(true);
                    } else {
                      setButtonClicked(false);
                    }
                  }
                }

                scene.setGameState(state);

                return state
              })
              break
            case 'endGame':
              setEndGame(payload)
              setTimeout(() => {
                setEndGame([])
                scene.endGame();
              },4000)
              break
            case 'notification':
              console.log(payload)
              break;
            case "getProfile":
              sessionStorage.setItem('playerProfile', JSON.stringify(parsedMessage.message));
              break;
            case "pong":
              clearTimeout(PongTimeoutRef.current);
              isTimeoutSet = false
              break;
            case 'kickGame':
              history('/')
              cleanupRef.current();
              window.Telegram.WebApp.BackButton.hide();
              break
          }
        } catch (error) {
          console.error('Ошибка при обработке сообщения:', error);
        }
      };

      websocket.onopen = () => {
        console.log('WebSocket подключен');
        setIsConnected(true);
        if (scene) {
          scene.websocket = websocket;
        }
        PingIntervalRef.current = setInterval(() => {
          if (websocket && websocket.readyState === WebSocket.OPEN) {
            websocket.send(JSON.stringify({ type: "ping" }));
            if (!isTimeoutSet) {
              isTimeoutSet = true;
              
              console.log('timeout sbrods')
              PongTimeoutRef.current = setTimeout(() => {
                websocket.close();
                websocket.onclose();
                isTimeoutSet = false;
              }, 5000);
            }
          }
        }, 5000);
      };

      websocket.onclose = () => {
        console.log('WebSocket отключен');
        setIsConnected(false);
        clearInterval(PingIntervalRef.current);
        setTimeout(() => {
          connectWebSocket(scene);
        }, 1000);
      };

      setIsSceneLoaded(true);
    }
  };

  useEffect(() => {
    if (window.Telegram.WebApp.backButtonHandler) {
        window.Telegram.WebApp.offEvent('backButtonClicked', window.Telegram.WebApp.backButtonHandler);
    }

    window.Telegram.WebApp.backButtonHandler = () => {
        if (state.lobbyState?.state === -1) {
          history('/')
          cleanupRef.current();
        } else {
            if (window.confirm("Вы уверены, что хотите вернуться назад?")) {
              history('/')
              cleanupRef.current();
            }
        }
    };

    window.Telegram.WebApp.onEvent('backButtonClicked', window.Telegram.WebApp.backButtonHandler);

    if (state.players && state.players.length > 0) {
      const intervalId = setInterval(() => {
        updateProgress(state.lobbyState);
      }, 1);

      return () => clearInterval(intervalId);
    }
  }, [state]);

  useEffect(() => {
    const config = {
      type: Phaser.AUTO,
      width: Math.round(window.innerWidth * window.devicePixelRatio ),
      height: Math.round(window.innerHeight * window.devicePixelRatio ),
      parent: 'phaser-game',
      scale: {
        mode: Phaser.Scale.FIT,
      },
      renderer: { mipmapFilter: 'LINEAR' },
      dom: {
        createContainer: true
      },
      transparent:true,
    };

    const phaserGame = new Phaser.Game(config);

    window.phaserg = phaserGame;

    phaserGame.events.on('scene-ready', function(scene) {
      if (scene) {
        connectWebSocket(scene);
      }
    });

    phaserGame.scene.add('main', MyGameScene, true); 

    const cleanup = () => {
      phaserGame.scene.remove('main');
      phaserGame.destroy(true);
      clearTimeout(PongTimeoutRef.current);
      clearInterval(PingIntervalRef.current)
      if (wsRef.current) {
        wsRef.current.onclose = null;
        wsRef.current.close();
      }
    };

    window.Telegram.WebApp.setHeaderColor("#19264b")

    //window.cleanup = cleanup

    //cleanupRef.current = cleanup;

    return () => {
      cleanup();
    }
  }, []);

  /*const webSocketReducer = (action) => {
    switch (action.type) {
      case 'getTable':
        return { table: action.payload };
      case 'trump':
        return { trump: action.payload };
      case 'getClientID':
        return { clientID: action.payload };
      case 'lobbyState':
        return {
          lobbyState: action.payload,
          players: action.payload.players,
          trump: action.payload.trumpCard,
          player: action.payload.players.find(player => player.id === window.Telegram.WebApp.initDataUnsafe.user.id),
          message: (action.payload.players.find(player => player.id === window.Telegram.WebApp.initDataUnsafe.user.id))?.hand.map((card, index) => ({
            ...card,
            index: index
          })) || null,
          table: action.payload.table,
        };
      default:
        console.error('Неизвестный тип сообщения:', action.type);
    }
  };*/

  const handleClick = () => {
    if (wsRef.current?.readyState === WebSocket.OPEN && !buttonClicked) {
      window.Telegram.WebApp.HapticFeedback?.impactOccurred('light');
      const lobbyState = state.lobbyState;
      const player = state.player;
  
      if (lobbyState?.state === -1) {
        wsRef.current.send(JSON.stringify({ type: "Ready" }));
      } else if (
        (lobbyState?.state === 2 && player?.role === 0) ||
        (lobbyState?.state === 3 && player?.role === 1)
      ) {
        wsRef.current.send(JSON.stringify({ type: "PassConfrim" }));
      } else if (player?.role === 1) {
        wsRef.current.send(JSON.stringify({ type: "takeCards" }));
      } else {
        wsRef.current.send(JSON.stringify({ type: "Pass" }));
      }
    } else {
      console.error('Соединение WebSocket закрыто');
    }
  };
   
  const clickInvite = () => {
    const inviteLink = `https://t.me/share/url?url=${encodeURIComponent(`https://t.me/durakgram_bot/durakgram?startapp=${id}`)}&text=${encodeURIComponent('\nЗаходи!')}`;

    window.Telegram.WebApp.openTelegramLink(inviteLink);
  };

  const userId = window.Telegram.WebApp.initDataUnsafe.user.id;
  const player = state?.players?.find(player => player.id === userId);

  return (
    <div className='game'>
      <div className={`loading ${ isSceneLoaded ? '' : 'visible'}`}>
        <div className='loading-icon'></div>
      </div>
      <div className={`websocket ${ !isConnected && 
        isSceneLoaded ? '' : 'visible'}`}>
        <h1>КОННЕКТ...</h1>
      </div>
      <div id="phaser-game" style={{ width: '100%', height: '100%' }}>
      <div className='header'>
      {playerBlocks.map((player, index) => (
          player ? (
            <div className='user' key={player.id}>
              <div className={`speech-bubble ${player.currentAction}`}>
                <p>
                  {player.currentAction === 'take' ? 'Беру' : 
                  player.currentAction === 'pass' ? 'Пас' :
                  player.currentAction === 'bito' ? 'Бито' :
                  player.currentAction === 'ready' ? 'Готов' : ''}
                </p>
              </div>
              <p>{player.name}</p>
              <div className={`inner-footer ${player.id}`} data-player-id={player.id} style={{ margin: 0, backgroundImage: `url(${player.photo_url})` }}>
                <div className={`endgame ${endGame.winner === player.id ? 'winner' : (endGame.loser === player.id ? 'loser' : '')}`}>
                  <div className="endgame-content">
                    <p>{endGame.winner === player.id ? '+' : (endGame.loser === player.id ? '-' : '')}100</p>
                  </div>
                  {endGame.winner === player.id && (
                    <img src="http://durakgram.com/asset/winner5.gif" alt="Winner"/>
                  )}
                  {endGame.loser === player.id && (
                    <img src="http://durakgram.com/asset/lose4.gif" alt="Loser"/>
                  )}
                </div>
                <svg className={`svg-progress`} viewBox="0 0 30 30">
                  <circle className="background" cx="15" cy="15" r="13" stroke="#ddd"></circle>
                  <circle className={`progress-bar r${player?.role}`} cx="15" cy="15" r="13" stroke="#4CAF50"></circle>
                </svg>
                <div className={`overlay ${!player.alive ? 'visible' : ''}`}>
                  <svg className="connection" width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M18 3.70825C19.2447 5.11852 20 6.97101 20 8.9999C20 11.0288 19.2447 12.8813 18 14.2915M6 3.70825C4.75527 5.11852 4 6.97101 4 8.9999C4 11.0288 4.75527 12.8813 6 14.2915" stroke="#FF2F2F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M15 6.35425C15.6224 7.05938 16 7.98563 16 9.00007C16 10.0145 15.6224 10.9408 15 11.6459M9 6.35425C8.37764 7.05938 8 7.98563 8 9.00007C8 10.0145 8.37764 10.9408 9 11.6459" stroke="#FF2F2F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M12 9V20" stroke="#FF2F2F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
                </div>
              </div>
            </div>
          ) : (
            <div className='user' key={`empty-${index}`}>
              <p>Пригласить</p>
              <div className='inner-footer'onClick={clickInvite} style={{
                margin: 0,
                backgroundSize: 'auto',
                backgroundImage: `url()`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                pointerEvents: 'auto',
                zIndex: 123
              }}>
                <svg className="dotted-svg" viewBox="0 0 100 100">
                  <circle className="dotted-circle" cx="50" cy="50" r="48" />
                </svg>
              </div>
            </div>
          )
        ))}
      </div>
        <div className='footer' id='footer'>
          <div className={`action-footer ${buttonClicked ? 'disabled' : ''}`} id="addPlayButton" onClick={handleClick}>
            <p>
              {state && (
                (state.lobbyState?.state === 2 && state.player?.role === 0) || 
                (state.lobbyState?.state === 3 && state.player?.role === 1)
              ) ? 'Пас' : 
              (
                state.lobbyState?.state === -1 ? 'Играть' : 
                (
                  state.player?.role === 0 ?
                  (state?.table?.length > 0 ? 'Бито' : 'Ваш ход') :
                  (state.player?.role === 1 ? 'Забрать' : '')
                )
              )}
            </p>
          </div>
          <div className={`inner-footer ${window.Telegram.WebApp.initDataUnsafe.user.id}`} data-player-id={window.Telegram.WebApp.initDataUnsafe.user.id} style={player ? { backgroundImage: `url(${player.photo_url})` } : {}}>
            <div className={`speech-bubble ${state.player?.currentAction}`}>
              <p>
                {state.player?.currentAction === 'take' ? 'Беру' : 
                state.player?.currentAction === 'pass' ? 'Пас' : 
                state.player?.currentAction === 'bito' ? 'Бито' : 
                state.player?.currentAction === 'ready' ? 'Готов' : ''}
              </p>
            </div>
            <div className={`endgame ${endGame.winner === window.Telegram.WebApp.initDataUnsafe.user.id ? 'winner' : (endGame.loser === window.Telegram.WebApp.initDataUnsafe.user.id ? 'loser' : '')}`}>
              <div className="endgame-content">
                <p>{endGame.winner === window.Telegram.WebApp.initDataUnsafe.user.id ? '+' : (endGame.loser === window.Telegram.WebApp.initDataUnsafe.user.id ? '-' : '')}100</p>
              </div>
              {endGame.winner === window.Telegram.WebApp.initDataUnsafe.user.id && (
                <img src="http://durakgram.com/asset/winner5.gif" alt="Winner"/>
              )}
              {endGame.loser === window.Telegram.WebApp.initDataUnsafe.user.id && (
                <img src="http://durakgram.com/asset/lose4.gif" alt="Loser"/>
              )}
            </div>
            <svg className={`svg-progress`} viewBox="0 0 30 30">
              <circle className="background" cx="15" cy="15" r="13" stroke="#ddd"></circle>
              <circle className={`progress-bar r${state.player?.role}`} cx="15" cy="15" r="13" stroke="#4CAF50"></circle>
            </svg>
          </div>
          <div className="balance-footer">
            <p>{JSON.parse(sessionStorage.getItem('playerProfile')).balance}</p>
            <img src='../asset/money.svg'></img>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GameComponent;
