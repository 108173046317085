import React, { useEffect, useState, createContext, useRef, useContext } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import GameComponent from './GameComponent';
import Lobbies from './Lobbies';
import Profile from './Profile';
import CreateGame from './CreateGame';
import Filter from './Filter';
import Leadboard from './Lead';
import Notification from './Notify';
import HistoryGame from './History';
import Rewards from './Rewards';

export const WebSocketContext = createContext(null);

function WebSocketProvider({ children }) {
    const wsRef = useRef(null);
    const location = useLocation();
    const [isConnected, setIsConnected] = useState(false);
    const [notifications, setNotifications] = useState([]);

    const connectWebSocket = () => {
        if (!wsRef.current || wsRef.current.readyState !== WebSocket.OPEN) {
            const websocket = new WebSocket(`wss://ws.durakgram.com/?initData=${encodeURIComponent(window.Telegram.WebApp.initData)}`);
            wsRef.current = websocket;

            websocket.onmessage = (event) => {
                try {
                    const parsedMessage = JSON.parse(event.data);

                    switch (parsedMessage.type) {
                      case "getProfile":
                        sessionStorage.setItem('playerProfile', JSON.stringify(parsedMessage.message));
                        setIsConnected(true);
                        break;
                    case "notification":
                        addNotification(parsedMessage.message);
                        break;
                    }
                } catch (error) {
                    console.error('Ошибка при обработке сообщения:', error);
                }
            };

            websocket.onopen = () => {
                console.log('WebSocket подключен');
            };

            websocket.onclose = () => {
                console.log('WebSocket отключен');
                setIsConnected(false);
                if (!location.pathname.includes('/game/')) {
                    setTimeout(connectWebSocket, 1000);
                }
            };
        }
    };

    const addNotification = (message) => {
        const id = Date.now();
        setNotifications(prevNotifications => [...prevNotifications, { id, message }]);
    };

    const removeNotification = (id) => {
        setNotifications(prevNotifications => prevNotifications.filter(notification => notification.id !== id));
    };

    useEffect(() => {
        if (!location.pathname.includes('/game/')) {
            connectWebSocket();
        } else {
            if (wsRef.current) {
                wsRef.current.onclose = null;
                wsRef.current.close();
                wsRef.current = null;
            }
        }

        if (window.Telegram.WebApp) {
            switch (location.pathname) {
                case '/profile':
                    window.Telegram.WebApp.setHeaderColor("#0C141B");
                    window.Telegram.WebApp.BackButton.hide();
                    break;
                case '/':
                    window.Telegram.WebApp.setHeaderColor("#19264b");
                    window.Telegram.WebApp.BackButton.hide();
                    break;
                case '/filter':
                    window.Telegram.WebApp.BackButton.show();
                    break;
                case '/leaderboard':
                    window.Telegram.WebApp.BackButton.show();
                    break;
                case '/history':
                    window.Telegram.WebApp.setHeaderColor("#19264b");
                    window.Telegram.WebApp.BackButton.show();
                    break;
                default:
                    window.Telegram.WebApp.setHeaderColor("#19264b");
            }
        }

        return () => {
            if (wsRef.current && location.pathname.includes('/game/')) {
                wsRef.current.close();
                wsRef.current = null;
            }
        };
    }, [location.pathname]);

    return (
        <WebSocketContext.Provider value={{ websocket: wsRef.current, isConnected, notifications, addNotification, removeNotification }}>
            {children}
        </WebSocketContext.Provider>
    );
}

function AppContent() {
    const location = useLocation();
    const history = useNavigate();
    const { isConnected, notifications, removeNotification } = useContext(WebSocketContext);

    useEffect(() => {
        if (window.Telegram.WebApp) {
            window.Telegram.WebApp.disableVerticalSwipes();
        }
    }, []);

    if (!isConnected && !location.pathname.includes('/game/')) {
        return (
            <CSSTransition in={!isConnected} classNames="fade" timeout={300} unmountOnExit>
                <div className='loading visible'>
                    <div className='loading-icon'></div>
                </div>
            </CSSTransition>
        );
    }

    return (
        <div className="App">
            <TransitionGroup className="notifications-container">
                {notifications.map(notification => (
                    <CSSTransition
                        key={notification.id}
                        timeout={500}
                        classNames="notify"
                        unmountOnExit
                    >
                        <Notification
                            message={notification.message}
                            onRemove={() => removeNotification(notification.id)}
                        />
                    </CSSTransition>
                ))}
            </TransitionGroup>
            {!location.pathname.includes('/game/') && (
                <div className='footer-navbar'>
                    <div className={`navbar-btn ${location.pathname === '/profile' ? 'active' : ''}`} onClick={() => history('/profile')}>
                        <img src="https://durakgram.com/asset/clubs.svg" alt="clubs" />
                        <p>Профиль</p>
                    </div>
                    <div className={`navbar-btn ${location.pathname === '/' ? 'active' : ''}`} onClick={() => history('/')}>
                        <img src="https://durakgram.com/asset/hearts.svg" alt="hearts" />
                        <p>Открытые</p>
                    </div>
                    <div className={`navbar-btn ${location.pathname === '/rewards' ? 'active' : ''}`} onClick={() => history('/rewards')}>
                        <img src="https://durakgram.com/asset/spades.svg" alt="spades" />
                        <p>Задания</p>
                    </div>
                    <div className={`navbar-btn ${location.pathname === '/create' ? 'active' : ''}`} onClick={() => history('/create')}>
                        <img src="https://durakgram.com/asset/diamonds.svg" alt="diamonds" />
                        <p>Создать игру</p>
                    </div>
                </div>
            )}
            <TransitionGroup>
                <CSSTransition key={location.pathname} classNames="page" timeout={300} unmountOnExit>
                    <Routes location={location}>
                        <Route path="/game/:id" element={<GameComponent />} />
                        <Route path="/" element={<Lobbies />} />
                        <Route path="/profile" element={<Profile />} />
                        <Route path="/rewards" element={<Rewards/>} />
                        <Route path="/create" element={<CreateGame />} />
                        <Route path="/filter" element={<Filter />} />
                        <Route path="/leaderboard" element={<Leadboard />} />
                        <Route path="/history" element={<HistoryGame />} />
                    </Routes>
                </CSSTransition>
            </TransitionGroup>
        </div>
    );
}

function App() {
    return (
        <Router>
            <WebSocketProvider>
                <AppContent />
            </WebSocketProvider>
        </Router>
    );
}

export default App;
