import React, { useEffect, useState, useContext, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

function Filter() {
  const history = useNavigate();
  const location = useLocation();

  const [filterSettings, setFilterSettings] = useState(() => {
    const savedSettings = localStorage.getItem('filterSettings');
    return savedSettings ? JSON.parse(savedSettings) : {
      bet: 0,
      players: 1,
      deck: 24,
      speed: 1,
      modes: ['classic', 'neigh', 'fair', 'trans']
    };
  });

  useEffect(() => {
    window.Telegram.WebApp.BackButton.show();
    if (window.Telegram.WebApp.backButtonHandler) {
        window.Telegram.WebApp.offEvent('backButtonClicked', window.Telegram.WebApp.backButtonHandler);
    }

    window.Telegram.WebApp.backButtonHandler = () => {
        history('/')
        window.Telegram.WebApp.BackButton.hide();
    };

    window.Telegram.WebApp.onEvent('backButtonClicked', window.Telegram.WebApp.backButtonHandler);
  }, []);

  // Обработчик изменения ставки
  const handleSliderChange = (event) => {
    const value = event.target.value;
    setFilterSettings((prevSettings) => {
      const newSettings = { ...prevSettings, bet: value };
      localStorage.setItem('filterSettings', JSON.stringify(newSettings));
      return newSettings;
    });

    const progress = (value / event.target.max) * 100;
    event.target.style.background = `linear-gradient(to right, #5585FF ${progress}%, rgba(0,0,0,0.2) ${progress}%)`;
  };

  // Обработчики для изменения других настроек
  const handlePlayerChange = (playerCount) => {
    setFilterSettings((prevSettings) => {
      const newSettings = { ...prevSettings, players: playerCount };
      localStorage.setItem('filterSettings', JSON.stringify(newSettings));
      return newSettings;
    });
  };

  const handleDeckChange = (deckSize) => {
    setFilterSettings((prevSettings) => {
      const newSettings = { ...prevSettings, deck: deckSize };
      localStorage.setItem('filterSettings', JSON.stringify(newSettings));
      return newSettings;
    });
  };

  const handleSpeedChange = (speedValue) => {
    setFilterSettings((prevSettings) => {
      const newSettings = { ...prevSettings, speed: speedValue };
      localStorage.setItem('filterSettings', JSON.stringify(newSettings));
      return newSettings;
    });
  };

  const incompatibleModes = {
    'classic': ['draw'],
    'draw': ['classic'],
    'flip': ['trans'],
    'trans': ['flip'],
    'all': ['neigh'],
    'neigh': ['all'],
    'bandit': ['fair'],
    'fair': ['bandit'],
  };

  // Функция для обработки выбора режима игры
  const handleModeChange = (mode) => {
    setFilterSettings((prevSettings) => {
      let newModes = [...prevSettings.modes];

      // Проверка на несовместимость с другими режимами
      const conflicts = incompatibleModes[mode] || [];

      // Проверяем, если режим уже выбран и конфликтующие режимы не включены
      if (!newModes.includes(mode)) {
        // Убираем конфликтующие режимы
        newModes = newModes.filter((m) => !conflicts.includes(m));
        // Добавляем новый режим
        newModes.push(mode);
      }

      const newSettings = { ...prevSettings, modes: newModes };
      return newSettings;
    });
  };

  const initialBackground = (value) => {
    const progress = (value / 5) * 100; // Так как max = 5
    return `linear-gradient(to right, #5585FF ${progress}%, rgba(0,0,0,0.2) ${progress}%)`;
  };

  return (
    <div className='filter'>
      <div className='container-filter'>
        <div className='vibor-up'>
          <label>Ваша ставка</label>
          <div className='range-slider'>
            <input
              type='range'
              min='0'
              max='5'
              className='range-input'
              value={filterSettings.bet}
              onChange={handleSliderChange}
              style={{ background: initialBackground(filterSettings.bet) }}
            />
            <div className='sliderticks'>
              <span>100</span>
              <span>1к</span>
              <span>10к</span>
              <span>100к</span>
              <span>1м</span>
              <span>10м</span>
            </div>
          </div>
        </div>

        <div className='vibor-center'>
          <div className='vibor'>
            <div className='vibor-header'>Игроков</div>
            <div className='vibor-body'>
              {[2, 3, 4, 5, 6].map((playerCount) => (
                <div
                  key={playerCount}
                  className={`vibor-btn ${filterSettings.players == playerCount ? 'active' : ''}`}
                  onClick={() => handlePlayerChange(playerCount)}
                >
                  {playerCount}
                </div>
              ))}
            </div>
          </div>

          <div className='vibor-center-down'>
            <div className='vibor'>
              <div className='vibor-header'>Колода</div>
              <div className='vibor-body'>
                {[24, 36, 52].map((deckSize) => (
                  <div
                    key={deckSize}
                    className={`vibor-btn ${filterSettings.deck == deckSize ? 'active' : ''}`}
                    onClick={() => handleDeckChange(deckSize)}
                  >
                    {deckSize}
                  </div>
                ))}
              </div>
            </div>

            <div className='vibor'>
              <div className='vibor-header'>Скорость</div>
              <div className='vibor-body'>
                {[1, 2].map((speedValue) => (
                  <div
                    key={speedValue}
                    className={`vibor-btn ${filterSettings.speed == speedValue ? 'active' : ''}`}
                    onClick={() => handleSpeedChange(speedValue)}
                  >
                    x{speedValue}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className='vibor-down'>
          <div className='vibor-header'>Режим игры</div>
          <div className='vibor-big'>
            <div className='vibor-body'>
              <div
                className={`vibor-btn-big ${filterSettings.modes.includes('flip') ? 'active' : ''}`}
                onClick={() => handleModeChange('flip')}
              >
                <img src="http://durakgram.com/asset/filter/flip.png" alt="Подкидной" />
                <span>Подкидной</span>
              </div>
              <div
                className={`vibor-btn-big ${filterSettings.modes.includes('trans') ? 'active' : ''}`}
                onClick={() => handleModeChange('trans')}
              >
                <img src="http://durakgram.com/asset/filter/trans.png" alt="Переводной" />
                <span>Переводной</span>
              </div>
            </div>

            <div className='vibor-body'>
              <div
                className={`vibor-btn-big ${filterSettings.modes.includes('classic') ? 'active' : ''}`}
                onClick={() => handleModeChange('classic')}
              >
                <img src="http://durakgram.com/asset/filter/classic.png" alt="Классика" />
                <span>Классика</span>
              </div>
              <div
                className={`vibor-btn-big ${filterSettings.modes.includes('draw') ? 'active' : ''}`}
                onClick={() => handleModeChange('draw')}
              >
                <img src="http://durakgram.com/asset/filter/draw.png" alt="Ничья" />
                <span>Ничья</span>
              </div>
            </div>

            <div className='vibor-body'>
              <div
                className={`vibor-btn-big ${filterSettings.modes.includes('neigh') ? 'active' : ''}`}
                onClick={() => handleModeChange('neigh')}
              >
                <img src="http://durakgram.com/asset/filter/neigh.png" alt="Соседи" />
                <span>Соседи</span>
              </div>
              <div
                className={`vibor-btn-big ${filterSettings.modes.includes('all') ? 'active' : ''}`}
                onClick={() => handleModeChange('all')}
              >
                <img src="http://durakgram.com/asset/filter/all.png" alt="Все" />
                <span>Все</span>
              </div>
            </div>

            <div className='vibor-body'>
              <div
                className={`vibor-btn-big ${filterSettings.modes.includes('bandit') ? 'active' : ''}`}
                onClick={() => handleModeChange('bandit')}
              >
              <img src="http://durakgram.com/asset/filter/bandit.png" alt="С шулерами" />
                <span>С шулерами</span>
              </div>
              <div
                className={`vibor-btn-big ${filterSettings.modes.includes('fair') ? 'active' : ''}`}
                onClick={() => handleModeChange('fair')}
              >
                <img src="http://durakgram.com/asset/filter/fair.png" alt="Честная игра" />
                <span>Честная игра</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Filter;
