import React, { useEffect, useState, useRef, useContext, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { WebSocketContext } from './App';

function Lobbies() {
  const location = useLocation();
  const history = useNavigate();
  const [lobbies, setLobbies] = useState(null);
  const [isSubscribe, setSubrscribe] = useState(false);
  const websocket = useContext(WebSocketContext);

  const handleLobbyMessage = useCallback((event) => {
    try {
      const parsedMessage = JSON.parse(event.data);

      //console.log(parsedMessage);

      switch (parsedMessage.type) {
        case "getLobbies":
          console.log(parsedMessage.message);
          setLobbies(parsedMessage.message);
          break;
        case "joinLobby":
          setTimeout(() => {
            history(`/game/${parsedMessage.message}`);
            window.Telegram.WebApp.BackButton.show();
          }, 1000);
          break;
        default:
          console.error('Неизвестный тип сообщения:', parsedMessage.type);
      }
    } catch (error) {
      console.error('Ошибка при обработке сообщения:', error);
    }
  }, [history]);

  useEffect(() => {
    if (websocket) {
      websocket.websocket.addEventListener('message', handleLobbyMessage);
      if (!isSubscribe) {
        websocket.websocket.send(JSON.stringify({ type: "subscribeLobbies" }));
        setSubrscribe(true)
      }
    }

    return () => {
      if (websocket) {
        websocket.websocket.removeEventListener('message', handleLobbyMessage);
      }
    };
  }, [websocket, handleLobbyMessage]);

  const JoinLobby = (id) => {
    let gameInfoMessage = {
      type: "joinLobby",
      id: id
    };

    if (websocket.websocket && websocket.websocket.readyState === WebSocket.OPEN) {
      websocket.websocket.send(JSON.stringify(gameInfoMessage));
    } else {
      console.error('Соединение WebSocket закрыто');
    }
  };

  return (
    <div className='lobby'>
      <div className="container-lby">
        {lobbies === null ? (
         <div className='loading-icon'></div>
        ) : (
          lobbies.map((lobby, index) => (
            <div className="item" key={index} onClick={() => JoinLobby(lobby.id)}>
              <div className="item-bet">
                <p>200</p>
                <img src='asset/money.svg'></img>
              </div>
              <div className="item-info">
                {lobby.players && Object.values(lobby.players).map(player => player.name).join(', ')}
                <div className="lby">
                  {lobby.players ? Object.keys(lobby.players).length : 0}/{lobby.maxPlayers}
                  <img src='asset/user.svg'></img>
                </div>
              </div>
              <div className="item-rules">
                <img src='asset/trans.svg'></img>
                <img src='asset/trans.svg'></img>
                <img src='asset/trans.svg'></img>
                <img src='asset/trans.svg'></img>
              </div>
            </div>
          ))
        )}
      </div>
      <div className='filter-lby' onClick={() => history('/filter')}>Фильтры</div>
    </div>
  );
}

export default Lobbies;
