import React, { useEffect, useState, useRef, useContext, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { WebSocketContext } from './App';

function HistoryGame() {
  const location = useLocation();
  const history = useNavigate();
  const [hisgame, setHisgame] = useState(null);
  const [isSubscribe, setSubrscribe] = useState(false);
  const websocket = useContext(WebSocketContext);

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleString();
  };

  const handleHistoryMessage = useCallback((event) => {
    try {
      const parsedMessage = JSON.parse(event.data);

      switch (parsedMessage.type) {
        case "getHistory":
          console.log(parsedMessage.message);
          setHisgame(parsedMessage.message);
          break;
        default:
          console.error('Неизвестный тип сообщения:', parsedMessage.type);
      }
    } catch (error) {
      console.error('Ошибка при обработке сообщения:', error);
    }
  }, [history]);

  useEffect(() => {
    window.Telegram.WebApp.BackButton.show();
    if (window.Telegram.WebApp.backButtonHandler) {
        window.Telegram.WebApp.offEvent('backButtonClicked', window.Telegram.WebApp.backButtonHandler);
    }

    window.Telegram.WebApp.backButtonHandler = () => {
        history('/profile')
        window.Telegram.WebApp.BackButton.hide();
    };

    window.Telegram.WebApp.onEvent('backButtonClicked', window.Telegram.WebApp.backButtonHandler);
  }, []);

  useEffect(() => {
    if (websocket) {
      websocket.websocket.addEventListener('message', handleHistoryMessage);
      if (!isSubscribe) {
        websocket.websocket.send(JSON.stringify({ type: "subscribeHistoryGame" }));
        setSubrscribe(true)
      }
    }

    return () => {
      if (websocket) {
        websocket.websocket.removeEventListener('message', handleHistoryMessage);
      }
    };
  }, [websocket, handleHistoryMessage]);

  return (
    <div className='history'>
      <div className="container-his">
        {hisgame === null ? (
         <div className='loading-icon'></div>
        ) : (
          hisgame.map((his, index) => (
            <div className="item" key={index}>
              <span>Winner: {his.winner.nickname}</span>
              <span>Loser: {his.loser.nickname}</span>
              <span>{formatDate(his.timestamp)}</span>
            </div>
          ))
        )}
      </div>
    </div>
  );
}

export default HistoryGame;
