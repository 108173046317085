import React, { useEffect, useState, useContext, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { WebSocketContext } from './App';

function Profile() {
  const history = useNavigate();
  const websocket = useContext(WebSocketContext);
  const [profile, setProfile] = useState(null);
  const location = useLocation();

  const handleProfileMessage = useCallback((event) => {
    try {
      const parsedMessage = JSON.parse(event.data);

      console.log(parsedMessage);

      switch (parsedMessage.type) {
        case "getProfile":
          console.log(parsedMessage.message);
          setProfile(parsedMessage.message);
          sessionStorage.setItem('playerProfile', JSON.stringify(parsedMessage.message));
          break;
        default:
          console.error('Неизвестный тип сообщения:', parsedMessage.type);
      }
    } catch (error) {
      console.error('Ошибка при обработке сообщения:', error);
    }
  }, []);

  useEffect(() => {
    /*if (websocket) {
      websocket.websocket.addEventListener('message', handleProfileMessage);
      websocket.websocket.send(JSON.stringify({ type: "subscribeProfile" }));
    }

    return () => {
      if (websocket) {
        websocket.websocket.removeEventListener('message', handleProfileMessage);
      }
    };*/

    setProfile(JSON.parse(sessionStorage.getItem('playerProfile')));
  }, [websocket]);

  return (
    <div className='profile'>
      {profile ? (
        <>
          <div className='profile-header'>
            <div class="profile-ava-container">
              <img className='avatar' src='https://durakgram.com/asset/unknow.jpeg' alt="avatar" />
              <img className='avatarblur' src='https://durakgram.com/asset/unknow.jpeg' alt="avatar" />
            </div>
            <div className='info'>
              <p>{window.Telegram.WebApp.initDataUnsafe.user.first_name} {window.Telegram.WebApp.initDataUnsafe.user.last_name}</p>
              <div className='right'>
                <div className='balance'>
                  <p>{profile.balance}</p>
                  <img src='asset/money.svg'></img>
                </div>
                <div className='btn-coin'>
                <img src='asset/icons/add.svg'></img>
                </div>
              </div>
            </div>
          </div>
          <div className='profile-navs'>
            <div className='profile-btn fp'>
                <span>Быстрая игра</span>
                <svg width="19" height="21" viewBox="0 0 19 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M17.4086 7.85258C19.5305 9.00647 19.5305 11.9935 17.4086 13.1474L4.59662 20.1145C2.53435 21.236 -1.03121e-07 19.7763 0 17.4671L6.22253e-07 3.5329C7.25375e-07 1.22368 2.53435 -0.235977 4.59661 0.885476L17.4086 7.85258Z" fill="white"/>
                </svg>
            </div>
            <div className='profile-btn' onClick={() => history('/history')}>
              <span>История игр</span>
              <img src='asset/money.svg'></img>
            </div>
            <div className='profile-btn' onClick={() => history('/leaderboard')}>
              <span>Лидеры</span>
              <img src='asset/money.svg'></img>
            </div>
            <div className='profile-btn' onClick={() => history('/leaderboard')}>
              <span>Достижения</span>
              <img src='asset/money.svg'></img>
            </div>
            <div className='profile-btn' onClick={() => history('/leaderboard')}>
              <span>Предметы</span>
              <img src='asset/money.svg'></img>
            </div>
          </div>
        </>
      ) : (
        <div style={{ margin: 'auto' }} className='loading-icon'></div>
      )}
    </div>
  );
}

export default Profile;
