import React, { useEffect } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

const Notification = ({ message, onRemove }) => {
    useEffect(() => {
        const timer = setTimeout(() => {
            onRemove(); // Удаление уведомления через 3 секунды
        }, 3000);

        return () => clearTimeout(timer); // Очищаем таймер при размонтировании
    }, [onRemove]);

    return (
        <div className='notify'>
            <div className='notify-body'>
                <img src='asset/notify/error.svg'></img>
                <div className='notify-body-text'>
                    <span style={{color:'rgba(255,255,255,0.5)'}}>Ошибка</span>
                    {message}
                </div>
            </div>
            <img onClick={onRemove} src='asset/notify/cancel.svg'></img>
        </div>
    );
};

export default Notification;
